.root {
  display: grid;
  grid-template-areas: "user content";
  grid-template-columns: 250px 1fr;
  grid-template-rows: 100%;
}

.tabs {
  [class*="TabsHeader"] {
    justify-content: flex-start;
    border-bottom: 1px solid #999;
    height: 36px;
    margin-bottom: 20px;
  }

  [aria-expanded="false"] {
    > div {
      border-color: transparent;
    }
  }
}

@media (max-width: 1056px) {
  .root {
    display: block;
  }
}
