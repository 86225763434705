.root {
  background: white;
}

.name {
  font-weight: 500;
  font-family: "Fredoka One", cursive;
  color: black;
}

.link {
  text-decoration: none;
}

.logo {
  margin-right: 10px;
  fill: black;
}

.newEvent {
  margin-right: 15px;

  div {
    width: 5px;
  }
}
