.going,
.noing {
  border-radius: 0 5px 5px 0;
  transition: all 0.23s;

  &:disabled {
    background: #7d4cdb;
    color: white;
    opacity: 1;
    border-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }

  &:focus {
    border-color: transparent;
    outline-color: transparent;
    box-shadow: none;
  }
}

.going {
  border-radius: 5px 0 0 5px;
}
