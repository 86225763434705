.icon {
  position: absolute;
  top: calc(50% - 64px);
  left: calc(50% - 52px);
  animation: loading 1s infinite ease-in-out;
}

.inline {
  position: initial;
  top: 0;
  left: 0;
}

@keyframes loading {
  0% {
    stroke: black;
    transform: rotate(0deg);
  }
  50% {
    stroke: deeppink;
    transform: rotate(180deg);
  }
  100% {
    stroke: black;
    transform: rotate(360deg);
  }
}
