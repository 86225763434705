.root {
  display: flex;
  align-items: center;

  & + .root {
    margin-top: 20px;
  }
}

.memberInfo {
  margin-left: 10px;
}

.displayName {
  margin: 0;
  font-size: 16px;
}
