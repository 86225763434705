.date {
  width: 150px;
  margin-right: 10px;

  input {
    padding: 5px 11px 11px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    -webkit-appearance: none;
    outline: none;
    background: transparent;
    color: inherit;
    font-weight: 600;
    border-radius: 4px;
    width: 100%;
    border: none;
    height: 45px;
  }
}

.dateWrapper {
  position: relative;
}

.datePlaceholder {
  position: absolute;
  height: 30px;
  background: white;
  pointer-events: none;
  top: 0;
  left: 12px;
  padding-top: 11px;
  font-weight: bold;
  color: #aaa;
  width: 100%;

  &.hide {
    display: none;
  }
}
