.root {
  position: relative;
  border-radius: 0 0 5px 5px;
}

.added,
.cta {
  position: absolute;
  top: 0;
  right: 0;
}

.added {
  top: 10px;
  right: 10px;
}

.link {
  div {
    width: 5px;
  }
}

.header {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 31ch;
  overflow: hidden;
}
