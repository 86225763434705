.root {
  margin: 0;
  font-style: italic;
  font-size: 14px;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  display: inline;

  &.default {
    background: #444;
  }

  &.pink {
    background: deeppink;
  }
}
